<template>
  <tr v-if="voeVolume">
    <td class="header">
      <div class="serial">
        <!-- <span class="num">{{index}}</span> -->
        <div class="company-logo">
          <img
            v-if="(user_preference_mode=='dark' && company.properties && company.properties.logo_dark) || (user_preference_mode=='light' && company.properties && company.properties.logo_light)"
            :src="
          user_preference_mode=='dark'
            ? company.properties.logo_dark
            : company.properties.logo_light
        "
            :alt="company.name"
            :title="company.name" />
          <span
            v-else
            class="company-name">{{company.name}}</span>
        </div>
      </div>
    </td>
    <td>
      <div
        class="review-volume"
        v-if="(voeVolume && (Number(voeVolume) > 0))">
          {{ voeVolume }}
      </div>
    </td>
    <td>
      <div
        class="rating-data">
        <p><span class="rating">{{voeData.summaryData.csat_ratings}}</span> out of 5</p>
        <div class="dissatisfied"><span class="rating-distribution">{{dissatisfiedDistribution}}%</span>&#9733;&#9733;&#9734;&#9734;&#9734; <span class="rating-label">1-2 stars</span></div>
        <div class="neutral"><span class="rating-distribution">{{neutralDistribution}}%</span>&#9733;&#9733;&#9733;&#9734;&#9734; <span class="rating-label">3 stars</span></div>
        <div class="satisfied"><span class="rating-distribution">{{satisfiedDistribution}}%</span>&#9733;&#9733;&#9733;&#9733;&#9733; <span class="rating-label">4-5 stars</span></div>
        <div class="null-value"><span class="rating-distribution">{{nullDistribution}}%</span>&#9734;&#9734;&#9734;&#9734;&#9734; <span class="rating-label">No Rating</span></div>
      </div>
    </td>
    <td>
      <div
        class="csat-trend" ref="barchartContainer"
        v-if="voeData.trendData && voeData.trendData.length">
        <bar-chart
          :chartData="voeData.trendData"
          :key="Number(csatRatings.toFixed(2))"
          :id="id"
          :avgRating="csatRatings"
          :companyName="company.name"
          :companyId="company.id" />
      </div>
    </td>
  </tr>
  <tr v-else>
    <td class="header">
      <div class="serial">
        <!-- <span class="num">{{index}}</span> -->
        <div class="company-logo">
          <img
            v-if="(user_preference_mode=='dark' && company.properties && company.properties.logo_dark) || (user_preference_mode=='light' && company.properties && company.properties.logo_light)"
            :src="
          user_preference_mode=='dark'
            ? company.properties.logo_dark
            : company.properties.logo_light
        "
            :alt="company.name"
            :title="company.name" />
          <span
            v-else
            class="company-name">{{company.name}}</span>
        </div>
      </div>
    </td>
    <td colspan="4" class="no-data">
      <div
        class="no-review"
        v-if="isEmpty">
        <b-icon class="nodata-icon" icon="information-outline"></b-icon>
        No reviews for {{company.name}} for the selection
      </div>
    </td>
  </tr>
</template>

<script>
import BarChart from './Barchart.vue'
import { mapActions, mapState } from 'vuex'

/**
 * This component is used to render the VoeSummary page info in a list view
 */
export default {
  components: {
    BarChart
  },
  props: {
    index: {
      type: Number
    },
    /**
     * ID of each row
     */
    id: {
      type: Number,
      default: 1
    },
    /**
     * Company whose information is displayed
     */
    company: {
      type: Object
    }
  },
  data () {
    return {
      voeData: {},
      isEmpty: false,
      barchartContainerWidth: null
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedCompaniesVoe',
      'subCategories',
      'ActiveVoeTimePeriod',
      'loading',
      'sortedCompanies',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('common', ['user_preference_mode', 'isCollapsed']),
    ...mapState('user', ['userDetails']),
    ...mapState('voesummary', [
      'voeStatistics',
      'maxReviewVolume'
    ]),
    csatRatings () {
      if (this.voeData && this.voeData.summaryData) {
        return this.voeData.summaryData.csat_ratings
      } else {
        return 0
      }
    },
    voeVolume () {
      if (this.voeData && this.voeData.summaryData) {
        return this.voeData.summaryData.review_volume
      } else {
        return 0
      }
    },
    width () {
      return (this.voeVolume / this.maxReviewVolume) * 100
    },
    dissatisfiedDistribution () {
      return Number((this.voeData.summaryData.dissatisfied_count / this.voeData.summaryData.review_volume) * 100).toFixed(1)
    },
    neutralDistribution () {
      return Number((this.voeData.summaryData.nutral_count / this.voeData.summaryData.review_volume) * 100).toFixed(1)
    },
    satisfiedDistribution () {
      return Number((this.voeData.summaryData.satisfied_count / this.voeData.summaryData.review_volume) * 100).toFixed(1)
    },
    nullDistribution () {
      return Number((this.voeData.summaryData.null_count / this.voeData.summaryData.review_volume) * 100).toFixed(1)
    }
  },
  watch: {
    company () {
      this.voeData = {}
      this.getStatistics()
    },
    isCollapsed () {
      if (!this.isCollapsed) {
        if (this.$refs.barchartContainer) {
          this.$refs.barchartContainer.style.setProperty('--list-view-barchart-width', this.barchartContainerWidth + 'px')
        }
      } else {
        if (this.$refs.barchartContainer) {
          this.$refs.barchartContainer.style.setProperty('--list-view-barchart-width', '')
        }
      }
    }
  },
  mounted () {
    this.isEmpty = false
    this.voeData = {}
    this.getStatistics()
  },
  updated () {
    if (this.$refs.barchartContainer) {
      this.barchartContainerWidth = this.$refs.barchartContainer.clientWidth
    }
  },
  methods: {
    ...mapActions('voesummary', [
      'getVoeStatistics'
    ]),
    /**
     * This method gets the data required to render the information for the VoeSummary view
     */
    async getStatistics () {
      if (
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod &&
        this.ActiveVoeTimePeriod.range[0] &&
        this.ActiveVoeTimePeriod.range[1] &&
        this.ActiveVoeTimePeriod.group_by
      ) {
        const data = {
          company: this.selectedCompaniesVoe.filter((obj) => Number(obj.id) === this.company.id)[0],
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
          businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
          seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`),
          groupBy: this.ActiveVoeTimePeriod.group_by
        }
        const res = await this.getVoeStatistics(data)
        if (res.trendData && res.trendData.length) {
          this.isEmpty = false
        } else {
          this.isEmpty = true
        }
        this.voeData = res
        this.$emit('data')
      } else {
        this.isNoData = true
        this.$emit('noData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.serial {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
}
.csat-trend {
  max-width: var(--list-view-barchart-width);
  width: 100%;
}
.voe-row-card {
  align-items: center;
  .company-logo {
    img {
      width: 120px;
      height: 49px;
      object-fit: contain;
      color: var(--chart-top-axis-color);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .company-name {
      color: var(--chart-top-axis-color);
      align-self: center;
      width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .fainted-logo {
      opacity: 1;
    }
  }
  .fainted-logo {
    opacity: 0.4;
  }
}
.review-volume {
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.19;
  color: var(--voe-list-review-volume);
  opacity: 1;
}
.no-review {
  padding: 20px 0px 15px 0px;
  color: var(--secondary-text-color);
  font-family: Quicksand;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.19;
  opacity: 0.4;
  display: flex;
  align-items: center;
  .nodata-icon {
    padding-right: 20px;
  }
}
.rating-data {
  color: var(--voe-list-rating-label);
}
.rating {
  color: var(--voe-list-rating);
  font-weight: 600;
  font-size: 30px;
}
.dissatisfied, .neutral, .satisfied, .null-value {
  color: #FEC007;
  font-size: 16px;
  display: flex;
  justify-items: stretch;
  .rating-distribution {
    display: inline-flex;
    justify-content: end;
    padding-right: 5px;
    width: 50px;
    font-weight: 600;
    color: var(--voe-list-rating-distribution);
  }
  .rating-label {
    color: var(--voe-list-rating-label);
    display: inline-flex;
    width: 33.33%;
    padding-left: 5px;
  }
}
table {
  .header {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
  }
  tbody tr {
    position: relative;
    border-bottom: solid 1px var(--voc-split-border-color);
    td {
      padding: 0px 10px;
      vertical-align: middle;
      min-width: 200px;
    }
    td:first-child {
      left: 0px;
      text-align: left;
      width: 200px;
      z-index: 9;
      background: var(--primary);
      color: var(--voe-list-header-color);
      box-shadow: -1px 0px 0px 0px var(--voc-split-border-color),
        1px 0px 0px 0px var(--voc-split-border-color); // display: flex;
      height: 176px;
      .company-logo {
        width: 160px;
        height: 49px;
      }
    }
    td:nth-child(2) {
      min-width: 200px;
    }
    td:nth-child(3) {
      text-align: left;
      padding-left: 30px;
      min-width: 280px;
    }
    td:nth-child(4) {
      min-width: 350px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .no-data {
      padding-left: 50px;
    }
  }
}
.header {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 9999;
}

@media screen and (min-width: 1700px) {
  .serial {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
  }
  .voc-row-card {
    align-items: center;
  }
  .review-volume {
    text-align: center;
    font-family: Quicksand;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.19;
    color: var(--voc-list-review-volume);
    opacity: 1;
  }
  .no-review {
    padding: 20px 0px 15px 0px;
    font-family: Quicksand;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.19;
    color: #4993ff;
    opacity: 1;
  }
  .no-review {
    color: var(--secondary-text-color);
    opacity: 0.4;
    // vertical-align: middle;
    display: flex;
    align-items: center;
    .nodata-icon {
      padding-right: 20px;
    }
  }
  .rating-data {
    color: var(--voc-list-rating-label);
  }
  .rating {
    color: var(--voc-list-rating);
    font-weight: 600;
    font-size: 36px;
  }
  .dissatisfied, .neutral, .satisfied {
    color: #FEC007;
    font-size: 16px;
    display: flex;
    justify-items: stretch;
    .rating-distribution {
      display: inline-flex;
      justify-content: end;
      padding-right: 5px;
      width: 50px;
      font-weight: 600;
      color: var(--voc-list-rating-distribution);
    }
    .rating-label {
      color: var(--voc-list-rating-label);
      display: inline-flex;
      width: 33.33%;
      padding-left: 5px;
    }
  }
  table {
    tbody tr {
      td:nth-child(3) {
        text-align: left;
      }
      td:nth-child(5) {
        display: flex;
      }
    }
  }
}
</style>
