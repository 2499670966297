<template>
  <div ref="vosParent">
    <div class="sort-header">
        <div class="is-flex">
          <div class="category-title">
            Employee Satisfaction Index (ESI)
          </div>
          <div class="voe-chart-toggle-container is-flex">
            <button class="average-rating" :class="[activeVoEView === 'grid' && 'active']" @click="updateActiveVoEView('grid')">Average Rating</button>
            <button class="review-volume" :class="[activeVoEView === 'list' && 'active']" @click="updateActiveVoEView('list')">Review Volume</button>
          </div>
        </div>
      <div class="voe-header">
        <global-sort-vue
          :selected="sort"
          :showSortBy="true"
          :options="sortOptions"
          @onChange="onChangeSelection" />
          <!-- <div v-if="activeVoEView === 'list'" class="toggle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.403" viewBox="0 0 16 14.403" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.4 0a1.6 1.6 0 00-1.15 2.707L10.33 8.55a1.313 1.313 0 00-.194-.019 1.6 1.6 0 00-.6.119L7.264 6.101a1.6 1.6 0 10-2.994-.769 1.588 1.588 0 00.394 1.038l-2.779 4.86a1.463 1.463 0 00-.284-.028 1.623 1.623 0 101.206.563l2.776-4.86a1.5 1.5 0 00.885-.091l2.269 2.554a1.6 1.6 0 102.547-.338l2.922-5.848a1.313 1.313 0 00.194.019 1.6 1.6 0 000-3.2z" fill="#c2c7de"/></svg>
            <ToggleButtonVue
              :toggle="showTrendLine"
              label="Trend Line"
              @onChange="onChangeTrendLine" />
          </div> -->
        <!-- <view-selector /> -->
      </div>
    </div>
    <div>
      <div
        class="stat-container"
        v-show="!isNoData">
        <div class="category-container">
          <div class="main-card" v-if="activeVoEView === 'list'">
            <div class="custom-table-container-block">
              <div class="voe-row-card-container">
                <table>
                  <thead>
                    <th>Company</th>
                    <th>Reviews</th>
                    <th>Average Rating</th>
                    <!-- <th>Recommendation (%)</th> -->
                    <th>
                      <div class="voe-legends">
                        <div class="voe-volume-legend voe">
                          <ul>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#c2c7de" d="M12 16V2h3v14zm-6 0V0h3v16zm-6 0V6h3v10z"/></svg>
                            <p>Review volume</p>
                          </ul>
                        </div>
                        <div class="voe-volume-legend voe">
                          <ul class="line-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="130.221 187.116 34 11.14" width="30" height="10" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path d="M161.22 191.69h-30" id="a"/><path d="M149.79 191.69c0 1.97-1.6 3.57-3.57 3.57-1.97 0-3.57-1.6-3.57-3.57 0-1.97 1.6-3.57 3.57-3.57 1.97 0 3.57 1.6 3.57 3.57z" id="b"/></defs><use xlink:href="#a" fill="#583fcc"/><use xlink:href="#a" fill-opacity="0" stroke="var(--avg-rating-symbol-line)"/><g><use xlink:href="#b" fill="var(--avg-rating-symbol-circle)"/></g></svg>
                            <p>Avg. rating</p>
                          </ul>
                        </div>
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    <template
                    class="row-border"
                      v-for="(company, index) in sortedCompanies"
                      :id="'id' + company.id">
                      <VoeRowCard
                      :key="index"
                      :company="company"
                      :index="index + 1"
                      :showTrendLine="showTrendLine"
                      @noData="setNoData(true)"
                      @data="setNoData(false)" />
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="voe-card-container" v-if="activeVoEView === 'grid'">
            <div
              v-for="(company, index) in sortedCompanies"
              :key="index"
              :id="'id' + company.id"
              class="psudo-container">
              <VoeCard :company="company" @noData="setNoData(true)" @data="setNoData(false)" />
            </div>
          </div>
        </div>
      </div>
      <NoDataContainerVue v-show="isNoData" class="mt-5" />
    </div>
  </div>
</template>

<script>
import VoeCard from '@/components/voeSummary/VoeCard.vue'
import VoeRowCard from '@/components/voeSummary/VoeRowCard.vue'
import NoDataContainerVue from '@/components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle, analyticsEvents } from '@/constant/data'
import { mapState, mapActions } from 'vuex'
import GlobalSortVue from '@/components/GlobalSort/GlobalSort.vue'

export default {
  name: 'VoeSummary',
  metaInfo () {
    return {
      title: metaTitle.voeSummary
    }
  },
  components: {
    VoeCard,
    NoDataContainerVue,
    GlobalSortVue,
    VoeRowCard
  },
  data () {
    return {
      isNoData: true,
      showDisplayOption: true,
      showTrendLine: false
    }
  },
  computed: {
    ...mapState('filters', [
      'loading',
      'companyList',
      'ActiveVoeTimePeriod',
      'selectedCompaniesVoe',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('voesummary', [
      'reviewVolume',
      'voeStatistics',
      'sortedCompanies',
      'sort',
      'sortOptions',
      'activeVoEView',
      'showText'
    ]),
    ...mapState('user', [
      'userDetails'
    ]),
    watchable () {
      return JSON.stringify(this.selectedCompaniesVoe) + JSON.stringify(this.ActiveVoeTimePeriod) + JSON.stringify(this.sort) + JSON.stringify(this.selectedEmployeeStatuses) + JSON.stringify(this.selectedBusinessFunc) + JSON.stringify(this.selectedSeniorityLevel)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getReviewVolume(true)
      }
    }
  },
  created () {
    const localView = localStorage.getItem('VOE_VIEW_TYPE')
    if (localView && localView !== this.activeVoEView) {
      this.updateActiveVoEView(localView)
    }
    this.updateShowText(true)
  },
  mounted () {
    this.getReviewVolume(true)
  },
  updated () {
    this.updateShowText(true)
  },
  methods: {
    ...mapActions('voesummary', [
      'getVoeVolume',
      'getSortedCompanies',
      'updateSort',
      'updateSortOptions',
      'updateActiveVoEView',
      'updateShowText'
    ]),
    /** This method changes the view */
    changeView (data) {
      this.updateActiveVoEView(data)
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'click',
        label: analyticsEvents.changeView,
        pageUrl: window.location.href
      })
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @param data {Object} - eg. { type: 'review_id', orderBy: 'asc' }
     * @public
     */
    onChangeSelection (data) {
      this.updateSort(data)
    },
    setNoData (value) {
      this.isNoData = value
    },
    async getReviewVolume (loadVolume) {
      if (
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod &&
        this.ActiveVoeTimePeriod.group_by
      ) {
        const data = {
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          groupBy: this.ActiveVoeTimePeriod.group_by,
          company: this.selectedCompaniesVoe.map(item => item.id),
          employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
          businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
          seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`),
          sort: this.sort
        }
        this.getSortedCompanies(data)
        if (loadVolume) {
          const res = await this.getVoeVolume(data)
          this.voeData = res
        }
        /**
         * This event is used to signify that the minimum data needed to render the view was successfully retrieved from the database.
         */
        this.$emit('data')
        this.isNoData = false
      } else {
        this.isNoData = true
        /**
         * This event is triggered when the minimum data needed to render the view is not available.
         */
        this.$emit('noData')
      }
    }
  }
}
</script>

<style lang="scss" src="./VoeSummary.scss" scoped />
