<template>
  <div class="voe-card">
    <div class="review-volume-main" :class="['border-bottom']">
      <div class="company-logo" :class="[voeVolume === 0 && 'hide-overflow']">
        <img
          v-if="(user_preference_mode=='dark' && company.properties && company.properties.logo_dark) || (user_preference_mode=='light' && company.properties && company.properties.logo_light)"
          :src="
            user_preference_mode=='dark'
              ? company.properties.logo_dark
              : company.properties.logo_light
          "
          :alt="company.name" :title="company.name" />
        <span
          v-else
          class="company-name" :class="[voeVolume > 0 && 'fixed-width']">{{company.name}}</span>
      </div>
      <!-- <div v-if="voeData.trendData && voeData.trendData.length" class="voe-legends">
        <div class="voe-volume-legend voe">
          <ul>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#c2c7de" d="M12 16V2h3v14zm-6 0V0h3v16zm-6 0V6h3v10z"/></svg>
            <p>Review volume</p>
          </ul>
        </div>
        <div class="voe-volume-legend voe" v-if="csatRatings">
          <ul class="line-icon">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="130.221 187.116 34 11.14" width="30" height="10" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path d="M161.22 191.69h-30" id="a"/><path d="M149.79 191.69c0 1.97-1.6 3.57-3.57 3.57-1.97 0-3.57-1.6-3.57-3.57 0-1.97 1.6-3.57 3.57-3.57 1.97 0 3.57 1.6 3.57 3.57z" id="b"/></defs><use xlink:href="#a" fill="#583fcc"/><use xlink:href="#a" fill-opacity="0" stroke="var(--avg-rating-symbol-line)"/><g><use xlink:href="#b" fill="var(--avg-rating-symbol-circle)"/></g></svg>
            <p>Avg. rating</p>
          </ul>
        </div>
      </div> -->
      <div
        class="review-volume">
        <p>{{voeVolume}}</p>
        <div>{{ voeVolume == 1 ? " Review" : " Reviews" }}</div>
      </div>
    </div>
    <template>
      <div
        class="doughnut"
        v-if="voeData.summaryData">
        <doughnut-chart
          :id="id"
          :chartData="voeData.summaryData"
          :csatRatings="csatRatings ? Number(csatRatings.toFixed(1)) : null"
          :companyName="company.name" />
      </div>
    </template>
    <div class="no-review" v-if="isEmpty">
      <p><b-icon class="nodata-icon" icon="information-outline"></b-icon></p>
      No reviews for {{company.name}}
      <p>for the selection</p>
    </div>
    <!-- <template>
      <div
        class="csat-trend"
        v-if="voeData.trendData && voeData.trendData.length">
        <bar-chart
          :chartData="voeData.trendData"
          :id="id"
          :avgRating="csatRatings"
          :companyName="company.name"
          :companyId="company.id" />
      </div>
    </template> -->
  </div>
</template>

<script>
import DoughnutChart from './DoughnutChart.vue'
import { mapActions, mapState } from 'vuex'

/**
 * This component is used to render the cards seen on the **VoeSummary** and **NPS** pages
 */
export default {
  components: {
    DoughnutChart
  },
  props: {
    /**
     * ID for each card
     */
    id: {
      type: Number,
      default: 1
    },
    /**
     * Product whose information is displayed
     */
    company: {
      type: Object
    },
    /** Indicates whether the card is being rendered for the NPS page */
    npsCard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      voeData: {},
      isEmpty: false,
      recommendationData: {}
    }
  },
  computed: {
    ...mapState('filters', [
      'ActiveVoeTimePeriod',
      'loading',
      'sortedCompanies',
      'selectedCategories',
      'selectedCompaniesVoe',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('common', ['user_preference_mode']),
    ...mapState('user', ['userDetails']),
    ...mapState('voesummary', [
      'voeStatistics',
      'chartView'
    ]),
    csatRatings () {
      if (this.voeData && this.voeData.summaryData) {
        return this.voeData.summaryData.csat_ratings
      } else {
        return 0
      }
    },
    voeVolume () {
      if (this.voeData && this.voeData.summaryData) {
        return this.voeData.summaryData.review_volume
      } else {
        return 0
      }
    }
  },
  watch: {
    company () {
      this.voeData = {}
      if (this.$route.name === 'voeSummary') {
        this.getStatistics()
      }
      if (this.$route.name === 'nps') {
        this.getNPS()
      }
    },
    ActiveVoeTimePeriod () {
      if (this.$route.name === 'voeSummary') {
        this.getStatistics()
      }
      if (this.$route.name === 'nps') {
        this.getNPS()
      }
    }
  },
  mounted () {
    this.isEmpty = false
    this.voeData = {}
    if (this.$route.name === 'voeSummary') {
      this.getStatistics()
    }
    if (this.$route.name === 'nps') {
      this.getNPS()
    }
  },
  methods: {
    ...mapActions('voesummary', [
      'getVoeStatistics'
    ]),
    /**
     * This method gets the data required to render the cards for the VoeSummary view
     */
    async getStatistics () {
      if (
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod &&
        this.ActiveVoeTimePeriod.range[0] &&
        this.ActiveVoeTimePeriod.range[1] &&
        this.ActiveVoeTimePeriod.group_by
      ) {
        const data = {
          company: this.selectedCompaniesVoe.filter((obj) => Number(obj.id) === this.company.id)[0],
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          groupBy: this.ActiveVoeTimePeriod.group_by,
          subscriptionId: this.selectedCategories.subscriptionId,
          employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
          businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
          seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`)
        }
        const res = await this.getVoeStatistics(data)
        if (res.trendData && res.trendData.length) {
          this.isEmpty = false
        } else {
          this.isEmpty = true
        }
        this.voeData = res
        this.$emit('data')
      } else {
        this.isNoData = true
        this.$emit('noData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .voe-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    .doughnut {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .company-logo {
      height: 49px;
      display: flex;
      align-items: center;
      img {
        width: 120px;
        height: 49px;
        object-fit: contain;
        color: var(--chart-top-axis-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .company-name {
        color: var(--chart-top-axis-color);
        align-self: center;
        text-overflow: ellipsis;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
      }
      .fainted-logo {
        opacity: 1;
      }
    }
    .hide-overflow {
      width: calc(100% - 75px);
    }
    .fixed-width {
      width: 120px;
    }
    .fainted-logo {
      opacity: 0.4;
    }
  }
  .recommendation-score {
    background: rgba(245, 119, 119, 0.2);
    background: var(--recommendation-score-background-color);
    text-align: left;
    padding: 10px 18px;
    display: flex;
    margin-top: auto;
    color: var(--recommendation-score-text-color);
    p {
      display: flex;
      opacity: 1;
      width: 100%;
      align-items: center;
    }
    span {
      opacity: 1;
      color: var(--inava-pink);
      font-weight: 700;
      margin-left: auto;
      display: flex;
      transition: 0.5s ease-out;
      font-size: 40px;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #C7C7C7;
  }
  .justify-content-center {
    justify-content: center;
  }
  .review-volume-main {
    display: flex;
    padding: 0px 0px 15px 0px ;
    justify-content: space-between;
    .review-volume {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: Quicksand;
      font-size: 16px;
      line-height: 1;
      color: var(--review-volume-text);
      opacity: 1;
      p {
        color: var(--review-volume-number);
        opacity: 0.8;
        font-size: 26px;
        font-weight: 700;
      }
    }
  }
  .voe-legends {
    display: flex;
    justify-content: center;
    .voe-volume-legend {
      display: flex;
      align-items: center;
      .icons path {
            fill: var(--voc-bar-background-color);
            fill-opacity: 1;
          }
      p {
        margin: 0px;
        color: #34343d;
        font-family: Quicksand, sans-serif;
        font-weight: 500;
        // font-size: 16px;
        padding-left: 10px;
        font-size: 12px;
        color: var(--fourth-text-color);
      }
      ul {
        list-style-type: none;
        display: flex;
        padding: 0px;
        margin: 0px;
        li {
          width: 10px;
          margin-right: 3px;
          height: 10px;
          background: #dadae4;
          border-radius: 4px;
          opacity: 1;
        }
      }
      .line-icon {
        padding-left: 20px;
      }
    }
  }
  .csat-trend {
    padding: 20px 0px 5px;
    margin: auto 0;
    display: flex;
  }
  .no-review {
    color :var(--secondary-text-color);
    opacity: 0.4;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    .nodata-icon {
      padding-bottom: 20px;
    }
  }
  .percentage-label {
    font-family: 'Quicksand', sans-serif;
    color: var(--word-cloud-text-color);
    text-align: left;
    margin-bottom: 2px;
    font-size: 0.875rem;
  }
  .percentage-value {
    font-size: 0.75rem;
    color: var(--inava-black);
    font-weight: 700;
  }
  ::v-deep .percentage-progress {
    display: flex;
    .progress-wrapper{
      width: 85%;
      margin-right: 10px;
      border-radius: 0 !important;
        margin-bottom: 1rem !important;
      .progress {
        border-radius: 0 !important;
        height: 18px !important;
      }
      .progress::-webkit-progress-bar,
      .progress-wrapper.is-not-native::-webkit-progress-bar {
        background-color: var(--voc-split-border-color);
      }
    }
  }

::v-deep .promoters {
    .progress::-webkit-progress-value,
    .progress-wrapper.is-not-native::-webkit-progress-value {
      background-color: var(--inava-primary);
    }
    .progress::-moz-progress-bar,
    .progress-wrapper.is-not-native::-moz-progress-bar {
      background-color: var(--inava-primary);
    }
    .progress::-ms-fill,
    .progress-wrapper.is-not-native::-ms-fill {
      background-color: var(--inava-primary);
      border: none;
    }
}

::v-deep .passives {
    .progress::-webkit-progress-value,
    .progress-wrapper.is-not-native::-webkit-progress-value {
      background-color: var(--inava-primary-light);
    }
    .progress::-moz-progress-bar,
    .progress-wrapper.is-not-native::-moz-progress-bar {
      background-color: var(--inava-primary-light);
    }
    .progress::-ms-fill,
    .progress-wrapper.is-not-native::-ms-fill {
      background-color: var(--inava-primary-light);
      border: none;
    }
}

::v-deep .detractors {
    .progress::-webkit-progress-value,
    .progress-wrapper.is-not-native::-webkit-progress-value {
      background-color: var(--inava-pink);
    }
    .progress::-moz-progress-bar,
    .progress-wrapper.is-not-native::-moz-progress-bar {
      background-color: var(--inava-pink);
    }
    .progress::-ms-fill,
    .progress-wrapper.is-not-native::-ms-fill {
      background-color: var(--inava-pink);
      border: none;
    }
}

@media screen and (max-width: 1800px) {
  .voe-card {
    .recommendation-score {
      background: rgba(245, 119, 119, 0.2);
      text-align: left;
      padding: 10px 18px;
      display: flex;
      margin-top: auto;
      p {
        display: flex;
        opacity: 1;
        width: 100%;
        align-items: center;
        font-size: 14px;
      }
      span {
        opacity: 1;
        color: var(--inava-pink);
        font-weight: 700;
        margin-left: auto;
        display: flex;
        font-size: 20px;
      }
    }
    .company-logo {
      img {
        width: 100px;
        height: 40px;
      }
      .company-name {
        color: var(--chart-top-axis-color);
        align-self: center;
        // width: 60%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .hide-overflow {
      width: calc(100% - 75px);
    }
    .fixed-width {
      width: 120px;
    }
    .justify-content-center {
      justify-content: center;
    }
    .review-volume-main {
      display: flex;
      padding: 0px 0px 7px 0px ;
        .review_volume{
        font-size: 13px;
        p {
          font-size: 20px;
        }
      }
    }
    .voe-legends {
      display: flex;
      justify-content: center;
      .voe-volume-legend {
        display: flex;
        align-items: center;
        .icons {
          width: 13px;
        }
        p {
          margin: 0px;
          color: #34343d;
          font-family: Quicksand;
          // font-size: 16px;
          padding-left: 5px;
          font-weight: 500;
          font-size: 11px;
          color: var(--fourth-text-color);
        }
        ul {
          list-style-type: none;
          display: flex;
          padding: 0px;
          margin: 0px;
          li {
            width: 10px;
            margin-right: 3px;
            height: 10px;
            background: var(--inava-primary-dark);
            border-radius: 4px;
            opacity: 1;
          }
        }
        .line-icon {
          padding-left: 15px;
        }
      }
    }
  }
  .barchart {
    .no-review {
      margin-top: 5px;
    }
    .csat-trend {
      padding: 0px 0px 5px;
      margin: auto 0;
      display: flex;
    }
  }
}
</style>
